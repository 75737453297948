<template>
  <div class="anonymize-cashflow padding-m">
    <h1>להפוך את התזרים לאנונימי</h1>
    <toggle label="להדליק/לכבות" class="margin-top-l margin-bottom-l" @input="toggleEnabled" :value="enabled"></toggle>
    <div class="flex-column">
      <div v-for="(p, index) in pairs" :key="index" class="flex-row pair-row">
        <text-input :on-input="onInputFunction(index, 'from')" label-text="הערך להחליף" :value="p.from" class="margin-left-s" :disabled="!enabled"/>
        <text-input :on-input="onInputFunction(index, 'to')" label-text="במה להחליף" :value="p.to" class="margin-left-s" :disabled="!enabled"/>
        <square-button title="" color="red" :icon-props="removeButtonProps" :disabled="!enabled" class="delete-button"
                       @click="remove(index)"/>
      </div>
    </div>
    <riseup-button class="margin-bottom-s" title="חדש" :disabled="!enabled" color="black" @click="addEmptyPair" />
    <riseup-button title="שמור" @click="save" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'RiseupPool',
  components: {
    TextInput: BaseUI.TextInput,
    RiseupButton: BaseUI.RiseupButton,
    SquareButton: BaseUI.SquareButton,
    Toggle: BaseUI.Toggle,
  },
  created() {
    this.initAnonymizeData();
  },
  computed: {
    ...mapState('anonymizeData', ['enabled', 'pairs']),
    removeButtonProps() {
      return { iconName: 'delete' };
    },
  },
  methods: {
    ...mapActions('anonymizeData', ['toggleEnabled', 'save', 'initAnonymizeData', 'addEmptyPair', 'removePair', 'updatePair']),
    remove(index) {
      this.removePair(index);
    },
    onInputFunction(index, key) {
      return value => {
        this.updatePair({ index, key, value });
      };
    },
  },
};

</script>

<style lang="scss" scoped>
::v-deep .delete-button .square-button {
  height: 56px;
  width: 56px;
}

</style>
