<template>
  <popup :close-action="closeActionClicked" :buttons="buttons" class="new-subscription-cta-popup">
    <template v-slot:title>
      <div class="padding-top-ml">
        <trial-state-snackbar v-if="!isHundredDaysPromotion" :close-modal="() => undefined" />
        <img class="cta-image margin-vertical-l" :src="image" />
        <div class="ri-large-headline">{{title}}</div>
        <div class="margin-top-s ri-large-body">{{text}}</div>
        <div v-if="secondaryText" class="margin-top-ml ri-large-body">{{secondaryText}}</div>
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import TrialStateSnackbar from '@/base-components/app-header/TrialStateSnackbar';
import promotions from '@/constants/promotions';
import router from '../../../../router';
import Segment from '../../../../Segment';
import { TRIAL_PERIODS } from '../../../../constants/subscription-state';

const imageByTrialPeriod = {
  // eslint-disable-next-line global-require
  [TRIAL_PERIODS.BEGINNING]: require('@/assets/subscription-cta/promo-sub-cta.png'),
  // eslint-disable-next-line global-require
  [TRIAL_PERIODS.MIDDLE]: require('@/assets/subscription-cta/promo-sub-cta.png'),
  // eslint-disable-next-line global-require
  [TRIAL_PERIODS.FINAL]: require('@/assets/subscription-cta/promo-sub-cta.png'),
};

const defaultTitleByTrialPeriod = {
  [TRIAL_PERIODS.BEGINNING]: 'עם מינוי רייזאפ פשוט יותר להיות בטוב עם הכסף שלך',
  [TRIAL_PERIODS.MIDDLE]: 'עם מינוי רייזאפ פשוט להרגיש טוב ובטוח עם ההוצאות שלך',
  [TRIAL_PERIODS.FINAL]: 'עם מינוי רייזאפ פשוט להתחיל לחסוך',
};

const defaultTextByTrialPeriod = {
  [TRIAL_PERIODS.BEGINNING]: 'עשית צעד ראשון ענק ויצאת לדרך עם תזרים רייזאפ. כדי להמשיך להיות על זה גם אחרי תקופת הנסיון רק צריך לבחור סוג מינוי.',
  [TRIAL_PERIODS.MIDDLE]: 'ידעת שלקוחות רייזאפ משפרים את התזרים ב־2,473 ₪ תוך 3 חודשים?',
  [TRIAL_PERIODS.FINAL]: '29% מלקוחות רייזאפ מתחילים לחסוך לעתיד שלהם תוך חצי שנה מההצטרפות לשירות.',
};

const defaultSecondaryTextByTrialPeriod = {
  [TRIAL_PERIODS.MIDDLE]: 'כדי להמשיך להיות על זה גם אחרי תקופת הנסיון רק צריך לבחור סוג מינוי.',
  [TRIAL_PERIODS.FINAL]: 'כדי להמשיך להיות על זה גם אחרי תקופת הנסיון רק צריך לבחור סוג מינוי.',
};

export default {
  name: 'NewSubscriptionCtaPopup',
  components: {
    TrialStateSnackbar,
    Popup: BaseUI.Popup,
  },
  created() {
    Segment.trackUserInteraction('SubscriptionCtaPopupShown', { trialPeriod: this.trialPeriod, newCtaVariant: true, benefitType: this.benefitType });
  },
  computed: {
    ...mapState('benefits', ['benefitType', 'benefitPercentage']),
    ...mapGetters('benefits', ['monthsText', 'isHundredDaysPromotion']),
    ...mapGetters('subscriptionState', ['trialPeriod']),
    config() {
      return {
        [promotions.BENEFIT_TYPES.FREE_MONTHS]: {
          title: `מגיעה לך הטבה של ${this.monthsText} בחינם!`,
          image: imageByTrialPeriod[this.trialPeriod],
          text: 'כדי לממש את ההטבה ולהמשיך להיות על זה גם אחרי תקופת הנסיון צריך לבחור סוג מינוי.',
          buttonTitle: 'למימוש ההטבה',
        },
        [promotions.BENEFIT_TYPES.PERCENTAGE_DISCOUNT]: {
          title: `מגיעה לך הטבה של ${this.monthsText} ב־${this.benefitPercentage}% הנחה!`,
          image: imageByTrialPeriod[this.trialPeriod],
          text: 'כדי לממש את ההטבה ולהמשיך להיות על זה גם אחרי תקופת הנסיון צריך לבחור סוג מינוי.',
          buttonTitle: 'למימוש ההטבה',
        },
        default: {
          title: defaultTitleByTrialPeriod[this.trialPeriod],
          // eslint-disable-next-line global-require
          image: require('@/assets/subscription-cta/cta-no-promo.png'),
          text: defaultTextByTrialPeriod[this.trialPeriod],
          secondaryText: defaultSecondaryTextByTrialPeriod[this.trialPeriod],
          buttonTitle: 'לבחירת מינוי',
        },
      };
    },
    variant() {
      if (this.isHundredDaysPromotion) {
        return this.config.default;
      }
      return this.config[this.benefitType || 'default'];
    },
    image() {
      return this.variant.image;
    },
    title() {
      return this.variant.title;
    },
    text() {
      return this.variant.text;
    },
    secondaryText() {
      return this.variant.secondaryText;
    },
    buttons() {
      return [{
        title: this.variant.buttonTitle,
        action: () => {
          Segment.trackUserInteraction('SubscriptionCtaPopup_SubscribeButtonClicked', { trialPeriod: this.trialPeriod, newCtaVariant: true });
          this.$emit('close');
          router.push({ path: '/subscription' });
        },
        variant: 'primary',
        size: 'slim',
      }];
    },
    isFinalTrialPeriod() {
      return this.trialPeriod === TRIAL_PERIODS.FINAL;
    },
  },
  methods: {
    ...mapActions('subscriptionState', ['ctaDismissed']),
    closeActionClicked() {
      Segment.trackUserInteraction('SubscriptionCtaPopup_CloseButtonClicked', { trialPeriod: this.trialPeriod, newCtaVariant: true });
      this.subscriptionCtaDismissed();
    },
    subscriptionCtaDismissed() {
      this.ctaDismissed();
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '~@riseupil/base-ui/src/scss/border-radius';

  .new-subscription-cta-popup {
    border-radius: $popup-border-radius;
    background-color: $riseup_beige;
    .cta-image{
      height: 100%;
      width: 100%;
    }
  }
</style>
