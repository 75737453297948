<template>
  <loader/>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loader from '@/base-components/Loader';
import router from '@/router';
import DDLogs from '@/DDLogs';
import Segment from '@/Segment';

export default {
  name: 'GeneratePromotionFlow',
  components: {
    Loader,
  },
  props: {
    promoSource: {
      type: String,
    },
  },
  async created() {
    Segment.trackUserGot('GeneratePromotionFlow_Entered', { promoSource: this.promoSource });
    if (this.promoSource) {
      try {
        await this.createPromotionForSource(this.promoSource);
        Segment.trackUserGot('GeneratePromotionFlow_Generated', { promoSource: this.promoSource, promoCode: this.validatedPromoCode });
        const query = { promoCode: this.validatedPromoCode };
        const icrc = this.$cookies.get('riseup.icrc');
        const utmParams = this.$cookies.get('riseup.utm_params');
        if (icrc && icrc !== 'undefined') {
          query.icrc = icrc;
          query.utm_source = 'ambassadors';
          query.utm_medium = 'referral';
          query.utm_campaign = 'ambassadors_customers';
        } else if (utmParams) {
          query.utm_source = utmParams.utm_source;
          query.utm_medium = utmParams.utm_medium;
          query.utm_campaign = utmParams.utm_campaign;
        }
        router.push({ path: '/signup', query });
        return;
      } catch (e) {
        DDLogs.error('Failed to generate promotion from GeneratePromotionFlow', { error: e, promoSource: this.promoSource });
        Segment.trackUserGot('GeneratePromotionFlow_Error', { promoSource: this.promoSource });
      }
    }
    router.push({ path: '/signup' });
  },
  computed: {
    ...mapState('promotions', ['validatedPromoCode']),
  },
  methods: {
    ...mapActions('promotions', ['createPromotionForSource']),
  },
};
</script>

<style lang="scss" scoped>
</style>
